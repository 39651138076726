import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button } from "@mui/material";
import { mutationCancelFraudMarkerById } from "api/dictv2-api";
import BaasGrid from "components/atoms/Grid/Grid";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import Endpoints from "constants/endpoints";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import moment from "moment";
import { useAccount } from "providers/account";
import { useOtp } from "providers/oneTimePassword";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const AntiFraudSearchList = ({
  data,
  loading,
  handleRefetch,
  loadMore,
  pagination,
}) => {
  const [modal, setModal] = useState({
    markerId: "",
  });
  const { setOpen } = useOtp();
  const history = useHistory();
  const isSandboxEnvironment = Endpoints.Environment === "Sandbox";

  const { accountType } = useAccount();

  const mutateCancel = useCallback(
    async (values) => {
      const response = await mutationCancelFraudMarkerById(values);
      if (response.status < 300) {
        toast.success(toastMessages.requestSuccess);
        handleRefetch();
        setOpen(false);
      } else {
        if ("errors" in response) {
          response.errors.map((error) => {
            return toast.error(error.message);
          });
        }
      }
    },
    [handleRefetch, setOpen]
  );

  const handleCancel = (markerId) => {
    if (!isSandboxEnvironment) {
      setOpen(true);
      setModal({ ...modal, markerId });
    } else {
      onSubmitCancel({ markerId });
    }
  };

  const onSubmitCancel = useCallback(
    (values, formik) => {
      mutateCancel({
        markerId: modal.markerId || values.markerId,
        accountType: accountType,
        otp: values.otp,
      });
    },
    [mutateCancel, modal.markerId, accountType]
  );

  const conditionalAction = (item) => {
    let conditions = [
      {
        onClick: () =>
          history.push({
            pathname: `/pix/v2/anti-fraud/details/${item.id}`,
          }),
        icon: InfoIcon,
        tooltip: "More info",
      },
      {
        onClick: () => handleCancel(item.id),
        icon: CancelOutlinedIcon,
        tooltip: "Cancel",
      },
    ];
    return conditions;
  };

  return (
    <>
      <Formik initialValues={{}}>
        {({ values, ...props }) => (
          <Form>
            <BaasOtpModal
              onConfirm={() => onSubmitCancel(values, props)}
              {...props}
            />
            <BaasGrid p="32px 0px">
              <DinamicTable
                canDowloadCsv={true}
                loading={loading}
                data={data}
                defaultSort={{
                  key: "status",
                  orientation: "desc",
                }}
                tableSchema={[
                  {
                    header: "Id",
                    key: "id",
                    tooltip: true,
                    style: {
                      maxWidth: "320px",
                      overflowWrap: "anywhere",
                    },
                  },
                  {
                    header: "Tax Id",
                    key: "taxIdNumber",
                    tooltip: true,
                    style: {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "noWrap",
                    },
                  },
                  {
                    header: "Created at",
                    key: "creationTime",
                    formatValue: (value) =>
                      moment(value).format("YYYY-MM-DD HH:mm:ss"),
                  },
                  {
                    header: "Key",
                    key: "key",
                  },
                  {
                    header: "Status",
                    key: "status",
                    sort: true,
                    tooltip: true,
                  },
                ]}
                getExtraActions={(data) => conditionalAction(data)}
              />
              <Box width="100%" display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  disabled={!Boolean(pagination?.hasMoreElements)}
                  onClick={() => loadMore()}
                >
                  Load more results
                </Button>
              </Box>
            </BaasGrid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AntiFraudSearchList;
