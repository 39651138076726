import { Button } from "@mui/material";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasIconButton from "components/atoms/IconButton/IconButton";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import BaasTable from "components/atoms/Table/Table";
import BaasTableCol from "components/atoms/Table/TableCol";
import BaasTableHeader from "components/atoms/Table/TableHeader";
import BaasTableHeaderCol from "components/atoms/Table/TableHeaderCol";
import BaasTableRow from "components/atoms/Table/TableRow";
import BaasText from "components/atoms/Text/BaasText";
import BaasTooltip from "components/atoms/Tooltip/Tooltip";
import { downloadCsvFile } from "helpers/csvMaker";
import { csvmaker } from "helpers/csvMaker";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  StyledArrowDown,
  StyledArrowUp,
  StyledHeaderContent,
  StyledSortWrapper,
} from "./styles";
import { useTheme } from "@mui/system";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Box } from "@mui/system";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

const DinamicTable = ({
  data = [],
  defaultSort,
  extraActions,
  canDowloadCsv = false,
  //Used to conditional render of icon passing data validate condition
  getExtraActions,
  loading,
  tableSchema,
  filterChildren,
}) => {
  const [sort, setSort] = useState(defaultSort);
  const [sortedData, setSortedData] = useState(data);
  const theme = useTheme();

  useEffect(() => {
    if (sort) {
      const sortData = _.orderBy(
        data,
        function (obj) {
          if (sort.sortType === "number") {
            // eslint-disable-next-line no-new-wrappers
            return new Number(_.get(obj, `${sort.key}`));
          }
          return _.get(obj, `${sort.key}`);
        },
        [sort.orientation]
      );
      setSortedData(sortData);
    } else {
      setSortedData(data);
    }
  }, [sort, sortedData.key, sortedData.orientation, data]);

  if (loading) {
    return (
      <>
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
      </>
    );
  }

  const getColValue = (schema, item) => {
    if (schema.extractItemValue) {
      return schema.extractItemValue(item);
    }
    if (schema.formatValue) {
      return schema.formatValue(_.get(item, `${schema.key}`));
    }
    return _.get(item, `${schema.key}`) || "N/A";
  };

  const handleSortChange = (key, orientation, sortType) => {
    setSort({
      key,
      orientation,
      sortType: sortType,
    });
  };

  const toggleOrientation = () => {
    if (sort.orientation === "asc") {
      return "desc";
    }
    return "asc";
  };

  const getSortIcon = (schema) => {
    const ArrowByOrientation =
      sort && sort.orientation === "asc" ? StyledArrowUp : StyledArrowDown;

    if (sort && schema.key === sort.key) {
      return (
        <ArrowByOrientation
          active={true}
          onClick={() =>
            handleSortChange(schema.key, toggleOrientation(), schema.sortType)
          }
        />
      );
    }

    return (
      <StyledArrowUp
        active={false}
        onClick={() => handleSortChange(schema.key, "asc")}
      />
    );
  };

  const handleDowload = () => {
    const csvdata = csvmaker(data);
    downloadCsvFile(csvdata);
  };

  const padding = canDowloadCsv ? "0px 0px" : "32px 0px";
  return (
    <>
      <BaasGrid p={padding}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          {canDowloadCsv && (
            <Box minWidth="150px">
              <Button
                startIcon={<InsertDriveFileIcon />}
                onClick={handleDowload}
              >
                Dowload Data
              </Button>
            </Box>
          )}
          {filterChildren && filterChildren}
        </Box>
        <BaasTable>
          <BaasTableHeader style={{ minWidth: "1000px", overflow: "auto" }}>
            {tableSchema.length > 0 &&
              tableSchema.map((schema) => (
                <BaasTableHeaderCol
                  {...schema.style}
                  key={schema.header}
                  width={schema.width || "auto"}
                >
                  <StyledHeaderContent>
                    {schema.header}
                    {schema.sort && (
                      <StyledSortWrapper>
                        {sortedData && sortedData.length > 0 && (
                          <>{getSortIcon(schema)}</>
                        )}
                      </StyledSortWrapper>
                    )}
                  </StyledHeaderContent>
                </BaasTableHeaderCol>
              ))}
            <BaasTableHeaderCol width="200px" flex="none">
              Actions
            </BaasTableHeaderCol>
          </BaasTableHeader>
          <>
            {!loading && sortedData && sortedData.length === 0 && (
              <Box
                style={{ background: theme.palette.grey[100] }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={1}
              >
                <HourglassEmptyIcon style={{ color: "black" }} />
                <BaasText>No Data Available</BaasText>
              </Box>
            )}
            {sortedData &&
              sortedData.length > 0 &&
              sortedData.map((item) => {
                const actions = getExtraActions
                  ? getExtraActions(item)
                  : extraActions;

                return (
                  <BaasTableRow
                    style={{ minWidth: "1000px", overflow: "auto" }}
                  >
                    {tableSchema.length > 0 &&
                      tableSchema.map((schema) => {
                        const ColWrapper = schema.tooltip
                          ? BaasTooltip
                          : React.Fragment;

                        const tooltipProps = schema.tooltip
                          ? {
                              title: getColValue(schema, item),
                              placement: "top",
                            }
                          : undefined;

                        return (
                          <BaasTableCol
                            key={schema.key}
                            width={schema.width}
                            style={schema.style}
                          >
                            <ColWrapper {...tooltipProps}>
                              <span>{getColValue(schema, item)}</span>
                            </ColWrapper>
                          </BaasTableCol>
                        );
                      })}
                    <BaasTableCol width="200px" flex="none">
                      <BaasGrid display="flex">
                        {actions &&
                          actions.length > 0 &&
                          actions.map((action, index) => (
                            <BaasGrid p="0px 4px" key={index}>
                              <BaasIconButton
                                onClick={() => action.onClick(item)}
                                color="grey"
                                variant="outlined"
                                tooltip={action.tooltip}
                                disabled={action.disabled}
                              >
                                <action.icon fontSize="medium" />
                              </BaasIconButton>
                            </BaasGrid>
                          ))}
                      </BaasGrid>
                    </BaasTableCol>
                  </BaasTableRow>
                );
              })}
            <BaasTableRow
              style={{ minWidth: "1000px", overflow: "auto" }}
            ></BaasTableRow>
          </>
        </BaasTable>
      </BaasGrid>
    </>
  );
};

export default DinamicTable;
