import CommomMethods from "assets/icons/documentation/CommomMethods.svg";
import Onboarding from "assets/icons/documentation/Onboarding.svg";
import Pix from "assets/icons/documentation/Pix.svg";
import PixIndirect from "assets/icons/documentation/PixIndirect.svg";
import PixMerchant from "assets/icons/documentation/PixMerchant.svg";
import Transaction from "assets/icons/documentation/Transaction.svg";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import BaasCardDocumentation from "components/molecules/CardDocumentation/CardDocumentation";
import React from "react";

const Documentation = () => {
  return (
    <>
      <BaasFlex flexDirection="column">
        <BaasGrid display="flex" justifyContent="center" width="100%">
          <BaasGrid sm={8} sx={{ maxWidth: "1440px" }} p={2}>
            <BaasGrid>
              <BaasText variant="h4" p="36px 0px">
                BTG Bank as a Service Documentation
              </BaasText>
              <BaasText variant="body1">
                Here you can find our documentation divided by sessions. Each
                session refers to a different set of functionalities and
                services to best suit our client's needs. Feel free to explore
                our services and if in need of assistance, please contact our
                team for support.
              </BaasText>
            </BaasGrid>
            <BaasGrid container spacing={3} p="32px 0px">
              {docs.map((doc) => (
                <>
                  <BaasGrid item xs={12} md={6} xl={4}>
                    <BaasCardDocumentation item={doc} />
                  </BaasGrid>
                </>
              ))}
            </BaasGrid>
          </BaasGrid>
        </BaasGrid>
      </BaasFlex>
    </>
  );
};

export default Documentation;

const docs = [
  {
    page: "/doccm",
    endpoint: "/cm/-doc",
    icon: CommomMethods,
    title: "Commom Methods",
    description:
      "Here you can find all the methods and functionalities that are commom and available to all our clients.",
    sessions: [
      "Security and Authentication",
      "Webhook",
      "Account",
      "Contact",
      "Monitoring",
      "Import File",
    ],
  },
  {
    page: "/docob",
    endpoint: "/ob/-doc",
    icon: Onboarding,
    title: "Onboarding",
    description:
      "This session refers to all onboarding functionalities and it's methods. Here you can find all about background check and the candidate`s application. Check it out!",
    sessions: ["Know Your Client (KYC)", "Account Onboarding"],
  },
  {
    page: "/doctr",
    endpoint: "/tr/-doc",
    icon: Transaction,
    title: "Transactions",
    description:
      "This session refers to functionalities regarding money transactions such as Internal and External transfers, Payment Slips Issuing and Bill and Taxes payment. Also, the Payment Institutions can find the Depositary Bank documentation regarding the credit card receivables flow.",
    sessions: [
      "Money Movements",
      "Payments",
      "Top Up",
      "Taxes Payments",
      "Clearing",
      "Depositary Bank",
      "Foreign Exchange",
    ],
  },
  {
    page: "/docpc",
    endpoint: "/pc/-doc",
    icon: Pix,
    title: "DICT & Pix Common Functionalities",
    description:
      "This session refers to the functionalities that are common to all Pix clients. These methods are available for both Pix Indirect and Pix Merchant Clients.",
    sessions: [
      "Pix Errors",
      "DICT Hub",
      "DICT Bacen Proxy",
      "Other Pix Methods",
    ],
  },
  {
    page: "/docpi",
    endpoint: "/pi/-doc",
    icon: PixIndirect,
    title: "Pix Indirect Participant",
    description:
      "This session includes all Pix Indirect Client methods and functionalities.",
    sessions: ["Indirect Client Pix Methods"],
  },
  {
    page: "/docpm",
    endpoint: "/pm/-doc",
    icon: PixMerchant,
    title: "Pix Merchant Clients",
    description:
      "This session includes all Pix Merchant Client methods and functionalities.",
    sessions: ["Merchant Dedicated Methods", "Pix Api V2.x (Merchant)"],
  },
  {
    page: "/docbk",
    endpoint: "/bk/-doc",
    icon: PixMerchant,
    title: "Open Finance",
    description:
      "This session includes all Open Finance methods and functionalities.",
    sessions: ["Openbanking Payment Initiation", "Open Finance Methods"],
  },
  // {
  //   page: "/docld",
  //   endpoint: "/ld/-doc",
  //   icon: CommomMethods,
  //   title: "Commom Methods",
  //   description: "Doc",
  //   sessions: [],
  // },
  // {
  //   page: "/docv1",
  //   endpoint: "/v1/-doc",
  //   icon: Legacy,
  //   title: "Legacy Documentation",
  //   description:
  //     "This session contains all legacy functionalities. New development should be made following the most up to date versions of our APIs and using the endpoints from the other sessions.",
  //   sessions: [],
  // },
];
