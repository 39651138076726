import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button } from "@mui/material";
import { mutationPostPixDictRefundByRefundIdCancel } from "api/dictv2-api";
import BaasGrid from "components/atoms/Grid/Grid";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import TableFilter from "components/organism/TableFilter/TableFilter";
import Endpoints from "constants/endpoints";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import moment from "moment";
import { useAccount } from "providers/account";
import { useOtp } from "providers/oneTimePassword";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import RefundSearchListModal from "./RefundSearchListModal";

const RefundSearchList = ({
  data,
  loading,
  handleRefetch,
  inputFilter,
  loadMore,
  setInputFilter,
  pagination,
  useLoadMore,
}) => {
  const [modal, setModal] = useState({
    state: false,
    id: "",
    refundId: "",
  });
  const { setOpen } = useOtp();
  const [filteredData, setFilteredData] = useState(data);

  const { accountSelected, accountType } = useAccount();
  const isSandboxEnvironment = Endpoints.Environment === "Sandbox";

  const mutateCancel = useCallback(async (values) => {
    const response = await mutationPostPixDictRefundByRefundIdCancel(values);
    if (response.status < 300) {
      toast.success(toastMessages.requestSuccess);
      handleRefetch();
      setOpen(false);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = (refundId) => {
    if (!isSandboxEnvironment) {
      setModal({ ...modal, refundId });
      setOpen(true);
    } else {
      onSubmit({ refundId });
    }
  };

  const ispb = accountSelected.markups
    .find((e) => e.indexOf("ISPB") !== -1)
    .split(":")[1];

  const onSubmit = useCallback(
    (values, formik) => {
      mutateCancel({
        otp: values.otp,
        refundId: modal.refundId || values.refundId,
        accountType: accountType,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOpen, modal, mutateCancel]
  );

  const conditionalAction = (item) => {
    let conditions = [
      {
        onClick: () =>
          setModal({
            state: true,
            id: item.id,
          }),
        icon: InfoIcon,
        tooltip: "More info",
      },
    ];
    if (accountType !== "Merchant") {
      conditions.push({
        onClick: () => handleCancel(item.id),
        icon: CancelOutlinedIcon,
        tooltip: "Cancel",
        disabled:
          accountType === "Merchant" ||
          (item.isCounterParty && item.creditedParticipant !== ispb) ||
          (item.isReporter && item.debitedParticipant !== ispb) ||
          item.status === "CANCELLED" ||
          item.status === "CLOSED",
      });
    }
    return conditions;
  };

  useEffect(() => {
    if (inputFilter) {
      setFilteredData(
        data.filter((infraction) => {
          return infraction.transactionId === inputFilter;
        })
      );
    } else {
      setFilteredData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFilter, data]);

  return (
    <>
      <Formik initialValues={{}}>
        {({ values, ...props }) => (
          <Form>
            <BaasOtpModal
              onConfirm={() => onSubmit(values, props)}
              {...props}
            />
            <BaasGrid p="32px 0px">
              <DinamicTable
                canDowloadCsv={true}
                loading={loading}
                data={filteredData}
                defaultSort={{
                  key: "status",
                  orientation: "desc",
                }}
                filterChildren={
                  <TableFilter
                    inputLabel="Filter by Transaction Id"
                    setFilterValue={setInputFilter}
                    inputWidth="290px"
                  />
                }
                tableSchema={[
                  {
                    header: "Transaction Id",
                    key: "transactionId",
                    tooltip: true,
                    style: {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "noWrap",
                    },
                  },
                  {
                    header: "Created at",
                    key: "creationTime",
                    formatValue: (value) =>
                      moment(value).format("YYYY-MM-DD HH:mm:ss"),
                  },
                  {
                    header: "Updated at",
                    key: "lastModified",
                    formatValue: (value) =>
                      moment(value).format("YYYY-MM-DD HH:mm:ss"),
                  },
                  {
                    header: "Status",
                    key: "status",
                    sort: true,
                    tooltip: true,
                  },
                ]}
                getExtraActions={(data) => conditionalAction(data)}
              />
              {useLoadMore && (
                <Box width="100%" display="flex" justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    disabled={!Boolean(pagination?.hasMoreElements)}
                    onClick={() => loadMore()}
                  >
                    Load more results
                  </Button>
                </Box>
              )}
            </BaasGrid>
            <RefundSearchListModal
              ispb={ispb}
              handleRefetch={handleRefetch}
              loadingSearch={loading}
              modal={modal}
              setModal={setModal}
              data={data}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RefundSearchList;
