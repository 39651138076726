import { Box, Button, TextField } from "@mui/material";
import MoneyInput from "@rschpdr/react-money-input";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";

export const OpenFinanceAmountPanel = ({ handleNext, values, setValues }) => {
  const handleChangeAmount = (value) => {
    setValues({ ...values, amount: value });
  };

  return (
    <>
      <BaasGrid item xs={6}>
        <BaasText variant="body2">
          Texto disclaimer explicando o fluxo. Cras mattis consectetur purus sit
          amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget
          quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
        </BaasText>
      </BaasGrid>
      <BaasGrid item xs={12}>
        <Box display="flex" mb={2} mt={2}>
          <BaasText variant="body1" sx={{ fontWeight: "bold" }}>
            Insert the amount value you want to apply in this process.
          </BaasText>
        </Box>
      </BaasGrid>

      <BaasGrid item xs={6}>
        <MoneyInput
          customInput={TextField}
          onChange={(e) => handleChangeAmount(e.target.value)}
          value={values?.amount || 0}
          currencyConfig={{
            locale: "pt-BR",
            currencyCode: "BRL",
            currencyDisplay: "symbol",
          }}
          style={{ margin: "8px 0px", width: "100%" }}
        />
      </BaasGrid>
      <BaasGrid item xs={12}>
        <Box mb={2} mt={3}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleNext}
            disabled={!values?.amount}
          >
            Next
          </Button>
        </Box>
      </BaasGrid>
    </>
  );
};
