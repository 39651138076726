import { useTheme } from "@mui/system";
import BaasText from "components/atoms/Text/BaasText";
import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/HeaderLogo.svg";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import { Link } from "@mui/material";
import { routesHeader } from "constants/routesHeader";
import BaasAmbientIndicator from "components/molecules/AmbientIndicator/AmbientIndicator";

const HeaderBackground = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.palette.grey[700]};
  display: flex;
  justify-content: center;
  position: ${(props) => props.position};
  /* position: ""; */
  z-index: 2;
`;

const HeaderButton = styled.div`
  padding: 14px;
  margin: 0px 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  opacity: 0.8;

  transition: box-shadow 200ms ease-out;
  cursor: pointer;
  :hover {
    box-shadow: inset 0 -2px 0 ${(props) => props.theme.palette.grey[100]};
    opacity: 1;
  }
`;

const BaasHeaderPublic = () => {
  const theme = useTheme();

  const checkCurrentRoute = () => {
    const paths = [
      "/doccm",
      "/docob",
      "/doctr",
      "/docpc",
      "/docpi",
      "/docpm",
      "/docld",
    ];
    let valid = false;
    paths.map((path) => {
      if (window.location.pathname.includes(path)) valid = true;
    });

    return valid;
  };

  const position = checkCurrentRoute() ? "" : "fixed";

  return (
    <>
      <HeaderBackground theme={theme} position={position}>
        <BaasGrid
          display="flex"
          justifyContent="space-between"
          p="14px"
          sx={{ maxWidth: "1440px", width: "100%" }}
        >
          <BaasGrid display="flex" alignItems="center">
            <Link href="/landing">
              <BaasButton
                backgroundColor="white"
                style={{ marginBottom: "8px", marginRight: "32px" }}
              >
                <Logo />
              </BaasButton>
            </Link>

            {routesHeader.map((route) => (
              <Link
                underline="none"
                color="grey.100"
                href={route.path}
                target={route.isExternal ? "_blank" : null}
              >
                <HeaderButton theme={theme}>
                  <BaasText variant="button">{route.name}</BaasText>
                </HeaderButton>
              </Link>
            ))}
          </BaasGrid>
          <BaasGrid display="flex" alignItems="center">
            <Link underline="none" color="grey.100" href="/login">
              <BaasButton variant="outlined" backgroundColor="white">
                Login
              </BaasButton>
            </Link>
            <BaasAmbientIndicator />
          </BaasGrid>
        </BaasGrid>
      </HeaderBackground>
      {position === "fixed" ? <div style={{ height: "84px" }} /> : null}
    </>
  );
};

export default BaasHeaderPublic;
