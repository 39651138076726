import BaasGrid from "components/atoms/Grid/Grid";
import React, { useEffect, useState } from "react";
import { RedocStandalone } from "redoc";
import styled from "styled-components";
import "swagger-ui-react/swagger-ui.css";
import Endpoints from "../../../constants/endpoints";

const StyledContainer = styled.div`
  /* height: calc(100vh - 90px);
  overflow-y: scroll; */
  width: calc(100vw - 8px);
`;

const DocumentationRedoc = () => {
  const [docEndpoint, setDocEndpoint] = useState();
  const url = window.location.pathname;

  useEffect(() => {
    docEndpointSelector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const docEndpointSelector = () => {
    switch (true) {
      case url.includes("/doccm"):
        setDocEndpoint("/cm/-doc");
        break;
      case url.includes("/docob"):
        setDocEndpoint("/ob/-doc");
        break;
      case url.includes("/doctr"):
        setDocEndpoint("/tr/-doc");
        break;
      case url.includes("/docpc"):
        setDocEndpoint("/pc/-doc");
        break;
      case url.includes("/docpi"):
        setDocEndpoint("/pi/-doc");
        break;
      case url.includes("/docpm"):
        setDocEndpoint("/pm/-doc");
        break;
      case url.includes("/docbk"):
        setDocEndpoint("/bk/-doc");
        break;
      case url.includes("/docld"):
        setDocEndpoint("/ld/-doc");
        break;
      // case url.includes("/docv1"):
      //   setDocEndpoint("/v1/-doc");
      //   break;
      default:
        return "failed";
    }
  };

  return (
    <>
      <BaasGrid>
        <StyledContainer>
          {docEndpoint ? (
            <BaasGrid>
              <RedocStandalone
                options={{
                  nativeScrollbars: false,
                }}
                specUrl={Endpoints.BaaSApiGateway + docEndpoint}
              />
            </BaasGrid>
          ) : null}
        </StyledContainer>
      </BaasGrid>
    </>
  );
};

export default DocumentationRedoc;
