import { Box, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import React, { useState, useCallback } from "react";
import BaasButton from "components/atoms/Button/Button";
import { useCustomMutation } from "helpers/useCustomMutation";
import { mutationQrCodePayment } from "api/baasTester/qrPayment-api";
import FaqSection from "components/organism/FaqSection/FaqSection";
import BaasBox from "components/atoms/Box/Box";
import { toast } from "react-toastify";

const QrCodePayment = () => {
  const theme = useTheme();
  const [emv, setEmv] = useState("");
  const [success, setSuccess] = useState(false);
  const { mutate, isSubmitting } = useCustomMutation(mutationQrCodePayment);

  const handleChange = (value) => {
    setEmv(value);
  };

  const onSubmitPayment = useCallback(
    (values, resetForm) => {
      mutate(
        {
          emv: emv,
        },
        (data) => {
          setEmv("");
          setSuccess(data.body?.body?.endToEndId);
        },
        (error) => {
          if (error.errorCode === "PBE7040") {
            toast.error(`Emvs created from QR Code Tester are not accepted. Please use
            the method in the FAQ section bellow to generate valid EMVs.`);
          } else {
            return toast.error(error.message);
          }
        }
      );
    },
    [emv, mutate]
  );

  return (
    <BaasFlex flex={1} flexDirection="column">
      {!success ? (
        <>
          <BaasPageHeader>Test Interface</BaasPageHeader>
          <BaasGrid
            item
            spacing={1}
            style={{ padding: "16px", marginBottom: "16px" }}
          >
            <BaasRow>
              <BaasText variant="h6">QR Code Payment</BaasText>
            </BaasRow>
            <BaasText variant="body2">
              Please, insert your EMV code to proceed for payment process.
              <Box>
                <Typography fontWeight="bold" color="error" variant="body2">
                  Emvs created from QR Code Tester are not accepted. Please use
                  the method in the FAQ section bellow to generate valid EMVs.
                </Typography>
              </Box>
            </BaasText>
          </BaasGrid>
          <BaasContentGrid spacing={4}>
            <BaasGrid
              item
              spacing={1}
              sx={{ background: theme.palette.grey[100], padding: 2 }}
            >
              <BaasGrid item>
                <TextField
                  autoFocus
                  name="emv"
                  margin="dense"
                  label="Emv Code"
                  type="text"
                  multiline
                  value={emv}
                  fullWidth
                  variant="standard"
                  onChange={(e) => handleChange(e.target.value)}
                />
              </BaasGrid>

              <BaasGrid item style={{ marginTop: "16px" }}>
                <BaasButton
                  loading={false}
                  disabled={!emv || isSubmitting}
                  variant="contained"
                  onClick={() => onSubmitPayment()}
                >
                  {isSubmitting ? "Processing" : "Start Payment"}
                </BaasButton>
              </BaasGrid>
              <BaasBox mt={5}>
                <FaqSection
                  messages={[
                    {
                      title: "Creating an Indirect Immediate QR Code",
                      description:
                        "You can create an Indirect Immediate QR code following the link below",
                      externalLink:
                        "https://developer.btgpactual.com/docpi#operation/post-pix-collection-location",
                      linkLabel: "Read Documentation",
                    },
                    {
                      title: "Creating an Indirect Due Date QR Code",
                      description:
                        "You can create an Indirect Due Date QR code following the link below",
                      externalLink:
                        "https://developer.btgpactual.com/docpi#operation/02-create-pix-collection-api-cobv-location",
                      linkLabel: "Read Documentation",
                    },

                    {
                      title: "Creating a Merchant Immediate QR Code",
                      description:
                        "You can create a Merchant Immediate QR code following the link below",
                      externalLink:
                        "https://developer.btgpactual.com/docpm#operation/post-pix-api-cob-abstracted-location",
                      linkLabel: "Read Documentation",
                    },
                    {
                      title: "Creating a Merchant Indirect Due Date QR Code",
                      description:
                        "You can create a Merchant Due Date QR code following the link below",
                      externalLink:
                        "https://developer.btgpactual.com/docpm#operation/post-pix-api-bacen-abstracted-location-cobv-txid",
                      linkLabel: "Read Documentation",
                    },
                  ]}
                />
              </BaasBox>
            </BaasGrid>
          </BaasContentGrid>
        </>
      ) : (
        <BaasTransactionSuccess
          pactualId={success}
          idLabel={"E2E Id"}
          onClick={() => {
            setSuccess(undefined);
          }}
        />
      )}
    </BaasFlex>
  );
};

export default QrCodePayment;
