import React from "react";
import BaasFlex from "components/atoms/Flex/Flex";
import LandingIntroduction from "./LandingIntroduction";
import LandingOurClients from "./LandingOurClients";
import LandingOnlyFewSteps from "./LandingOnlyFewSteps";
import LandingProducts from "./LandingProducts";
import LandingContactUs from "./LandingContactUs";
import BaasFooterLanding from "components/templates/Footer/FooterLanding";
import LandingSponsors from "./LandingSponsor";

const Landing = () => {
  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <LandingIntroduction />
        <LandingOurClients />
        <LandingSponsors />
        <LandingOnlyFewSteps />
        <LandingProducts />
        <LandingContactUs />
        <BaasFooterLanding />
      </BaasFlex>
    </>
  );
};

export default Landing;
