import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { useTheme } from "@mui/system";
import { useField, useFormikContext } from "formik";

const SelectStyle = styled.div``;

const WebhookConfigCheckbox = ({ name, item, label, ...props }) => {
  const theme = useTheme();
  const { setTouched } = useFormikContext();
  const [{ value }, , { setValue }] = useField(name);

  const onChange = (event) => {
    setTouched(name, true);
    setValue({ ...item, checked: event.target.checked });
  };

  return (
    <SelectStyle theme={theme}>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            sx={{
              color: theme.palette.grey[800],
              "&.Mui-checked": {
                color: theme.palette.grey[800],
              },
            }}
            checked={value.checked}
            onChange={(event) => onChange(event)}
            name={name}
            {...props}
          />
        }
      />
    </SelectStyle>
  );
};

export default WebhookConfigCheckbox;
