import "@brainhubeu/react-carousel/lib/style.css";
import abFintechs from "assets/logo_partners/sponsors/Abfintechs.png";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import React from "react";
import LandingOurClientsStyles from "./LandingOurClientsStyles";

const LandingSponsors = () => {
  const classes = LandingOurClientsStyles();

  return (
    <>
      <BaasGrid
        container
        diplay="flex"
        justifyContent="center"
        alignItems="center"
        p={6}
        style={{
          backgroundColor: "#fff",
          minHeight: "50vh",
        }}
      >
        <BaasGrid p="0px 0px 40px 0px">
          <BaasText variant="h4" color="grey.800">
            Official Sponsor
          </BaasText>
        </BaasGrid>
        <BaasGrid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <img alt="" src={abFintechs} className={classes["sponsorLogo"]} />
          </div>
        </BaasGrid>
      </BaasGrid>
    </>
  );
};

export default LandingSponsors;
